import $ from "jquery";

window.$ = window.jQuery = $;

var timeline;           //array
var dotsNotInTimeline   //array
var timelineLength;     //pixel
var startDate;          //date
var endDate;            //date
var timelineLengthNum;  //int
var timelineContents;   //array
var contentsNotInTimeline;  //array
var startDot;           //dot
//var prevBtn;            //clickable
//var nextBtn;            //clickable

//var months;
//var selectedMonth;
var years;
var selectedYear;
var selectedDot;
const minDistance = 5;
var lastDotPosition = 0;
var screenWidth;
var screenHeight;




$(document).ready(function () {
    /*
        console.log(timeline[0].getAttribute("data-date"));
        console.log(timelineLength);
        timeline[0].style.left = timelineLength + 'px';
        const date1 = new Date('7/13/2010');
        const date2 = new Date('12/15/2010');
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        console.log(diffTime + " milliseconds");
        console.log(diffDays + " days");
        monthsInit();
        prevBtn = document.getElementById('prev');
        nextBtn = document.getElementById('next');
        prevBtn.addEventListener('click', pressPrev);
        nextBtn.addEventListener('click', pressNext);
     */
    //debug("Document.ready")
    screenWidth = $(window).width();
    screenHeight = $(window).height();
    timelineInit(true);
})


/*
    Inicializálja a timeline minden elemét
 */
function timelineInit (firstRun) {
    //debug("timelineInit")
    if(firstRun) {
        window.addEventListener('resize', resizeTimeline);
        document.getElementById('prev').addEventListener('click', pressPrev);
        document.getElementById('next').addEventListener('click', pressNext);
        yearsInit();
    }
    defaultDisplay();
    initDots();
    initContents();
    selectDotWithParam(selectedDot,'Next');
    rotateMonthLabel();
}

function dateFromStart (date,startDate) {
    const diffTime = Math.abs(date - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //console.log(diffDays + 'date');
    return diffDays;
}

function resizeTimeline() {
    if($(window).width() !== screenWidth ){
        timelineInit(false);
    }
}


/*
function monthsInit() {
    months = document.querySelectorAll('#month .btn.timeline');
    months[0].classList.add("active");

    months.forEach( month => month.addEventListener('click', monthClick))
}

function monthClick() {
    months.forEach(month => month.classList.remove("active"))
    this.classList.add("active")
    selectedMonth = this;
}
*/


function selectDot () {
    //debug("selectDot")
    timeline.forEach(dot => {
        dot.classList.remove('selected');
    });
    this.className = 'selected';
    selectContentToDot(this,"up");
}

function selectDotWithParam (dot,direction) {
    //debug("selectDotWithParam")
    timeline.forEach(dot => {
        dot.classList.remove('selected');
    });
    dot.className = 'selected';
    selectContentToDot(dot,direction);
}

function selectContentToDot (dot, direction) {
    //debug("selectContentToDot")
    timelineContents.forEach(content_card => {
        if (content_card.getAttribute("data-id") === dot.getAttribute("data-id")) {
            content_card.className = "selected"
            content_card.style.display = "block"
            if(direction === "next")
                content_card.style.animation = 'cd-enter-right';
            else if(direction === "prev")
                content_card.style.animation = 'cd-enter-left';
            else
                content_card.style.animation = 'cd-enter-bottom';
            content_card.style.animationDuration = '2s';
        }else {
            if(content_card.classList.contains('selected') && direction==='up') {
                content_card.classList.remove('selected');
                content_card.style.animation = 'cd-enter-bottom';
                content_card.style.animationDuration = '.4s';
                content_card.style.animationDirection = 'reverse';
            }else {
                content_card.classList.remove('selected');
            }
        }
    } )
}

function pressNext(){
    var direction = "next";
    var index = 0;
    for( var i = 0; i< timeline.length; i++) {
        if(timeline[i].className === 'selected') {
            index = i;

            timelineContents[i].style.animation = 'cd-enter-left';
            timelineContents[i].style.animationDuration = '.4s';
            timelineContents[i].style.animationDirection = 'reverse';
        }
    }
    if(index >= timeline.length-1) {
        selectDotWithParam(timeline[0],direction);
    }else {
        selectDotWithParam(timeline[index+1],direction);
    }
}

function pressPrev(){
    var direction = "prev";
    var index = 0;
    for( var i = 0; i< timeline.length; i++) {
        if(timeline[i].className === 'selected') {
            index = i;

            timelineContents[i].style.animation = 'cd-enter-right';
            timelineContents[i].style.animationDuration = '.4s';
            timelineContents[i].style.animationDirection = 'reverse';
        }
    }
    if(0 === index) {
        selectDotWithParam(timeline[timeline.length-1],direction);
    }else {
        selectDotWithParam(timeline[index-1],direction);
    }
}

function transitionend() {
        //debug("transitionend")
        this.style.animation = 'initial';
        if(this.classList.contains('selected'))
            this.style.display = 'block'
        else
            this.style.display = 'none'
}


/*
    Ellenőrzöm az összes timeline-dot évszám attribútumát, az alapján hogy megegyezik-e a kiválaszott évvel,
    inicializálom a timeline,dotsNotInTimeLine változókat
 */
function queryDots() {
    //debug("queryDots")
    var timelineDots = document.querySelectorAll('ol#timeline-dots li');
    timeline = [];
    dotsNotInTimeline = [];
    timelineDots.forEach( dot => {
        if(new Date (dot.getAttribute("data-date")).getFullYear() === new Date (selectedYear.getAttribute("data-date")).getFullYear()){
            timeline.push(dot);
        } else {
            dotsNotInTimeline.push(dot)
        }
    })
    return [timeline,dotsNotInTimeline]
}


/*
    Inicializalom a timeline-t a timeline-dots -okkal, amiknek az évszáma megegyezik a kiválasztott évszámmal
    Inicializalom a dotsNotInTimeline-t a nem megjelenítendő timeline-dots -okkal, ezek display tulajdonságát none-ra állítom
    Inicializálom a timelineLength az idővonal hosszával pixelben
    Inicializálom a kezdő dátumot, a kiválasztott év első napjával
    Inicializálom a végső dátumot, a kiválasztott év utolsó napjával
    Inicializálom a selectedDot-ot a kiválasztottak közül, az első timeline-dot -al
    Minden timeline-dot megkapja az arányos távolságát pixelben
    Minden timeline-dot "click" event-re végrehajtja a selectDot() fgv-t
 */
function initDots(){
    //debug("initDots")
    let allDots = queryDots();
    timeline = allDots[0];
    dotsNotInTimeline = allDots[1];
    dotsNotInTimeline.forEach(dot => dot.style.display = 'none');
    selectedDot = timeline[0];
    timelineLength = document.querySelector('div#line hr').offsetWidth;

    startDate = new Date(new Date (selectedYear.getAttribute("data-date")).getFullYear(),0,1);
    endDate = new Date(new Date (selectedYear.getAttribute("data-date")).getFullYear()+1,0,1);

    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    timelineLengthNum = diffDays;

    timeline.forEach(dot => {
        let bias = dateFromStart(new Date(dot.getAttribute("data-date")),startDate) / timelineLengthNum * timelineLength;
        //let bias = timelineLength/11/33.18181818 * dateFromStart(new Date(dot.getAttribute("data-date")),startDate)
        if(bias > 0) {
            if (bias - lastDotPosition < minDistance) {
                bias = bias + minDistance;
            }
        }

        dot.style.left = bias + 'px'
        lastDotPosition = bias;
    });
    timeline.forEach(dot => {
        dot.addEventListener('click', selectDot)
    });
}


/*
    Ellenőrzi a timeline-content objektumok data-date attributumat és ha egyeznek a selectYear évszámával,
    akkor a timelineContents array-be, ha nem, akkor a contentsNotInTimeline array-be kerülnek.
 */
function queryContents() {
    //debug("queryContents")
    var timelineContents_temp = document.querySelectorAll('ol#timeline-contents li');
    timelineContents = [];
    contentsNotInTimeline = [];
    timelineContents_temp.forEach( content_card => {
        if(new Date (content_card.getAttribute("data-date")).getFullYear() === new Date (selectedYear.getAttribute("data-date")).getFullYear()){
            timelineContents.push(content_card);
        } else {
            contentsNotInTimeline.push(content_card);
        }
    })
    return [timelineContents,contentsNotInTimeline]
}


/*
    Inicializalom a timelineContent array-t timeline-content objektumokkal, amiket a queryContents kiválasztott
    contentsNotInTimeline-t szintén azokkal a timelineContent objektumokkal, amik nem a selectedYear-hez
    tartoznak, ezeket display=none -ra állítom
    Minden megjelenítendő timeline-content, animationend eseményére lefut a transitionend() fgv
    Meghívom a selectContentToDot() fgv-t
 */
function initContents() {
    //debug("initContents")
    let allContents = queryContents();
    timelineContents = allContents[0];
    contentsNotInTimeline = allContents[1];

    contentsNotInTimeline.forEach(content_card => {
        content_card.style.display = 'none';
    });
    timelineContents.forEach(content_card => content_card.addEventListener("animationend", transitionend));
    selectContentToDot(selectedDot,'next');
}


/*
    Console.log
 */
function debug(msg){
    console.debug(msg)
}


/*
    Inicializalja az első év gombot (2017) "active"-ra
    Az összes év gombhoz "click" event eállítja a yearsClick() fgv-t
    Beállítja a selectedYears értékét
 */
function yearsInit() {
    //years = document.querySelectorAll('#years .btn.timeline');
    years = document.querySelectorAll('#years li');
    years = Array.from(years);
    years = years.slice(1,6);
    years[0].classList.add("active");
    selectedYear = years[0];
    years.forEach( year => year.addEventListener('click', yearsClick))
}


/*
    Végig megy az összes év gombon, eltávolítja az "active" osztálynevet
    Hozzáadja az aktuálisan megnyomott gombhoz az "active" osztálynevet
    Beállítja a selectedYears értékét
    Újra inicializálja a timeline-t
 */
function yearsClick() {
    years.forEach(year => year.classList.remove("active"))
    this.classList.add("active")
    selectedYear = this;

    /*
        Ha túl gyorsan kattintanak, nem fejeződnek be animációk, és azok eltárolódnak, ezért törölni kell őket mindíg!
     */
    timelineContents.forEach(content => {
        if(content.classList.contains('selected')){
            content.style.animation = 'initial';
        }
    })

    timelineInit(false)
}

/*
    Minden timeline-dot és timeline-content style.display = 'block'
 */
function defaultDisplay() {
    //debug("defaultDisplay")
    var dots = document.querySelectorAll('ol#timeline-dots li');
    var content = document.querySelectorAll('ol#timeline-contents li');

    dots.forEach(dot => dot.style.display = 'block');
    //content.forEach(content_card => content_card.style.display = 'none');
}


/*
    A képernyő szélesség függvényében elforgatja a hónap neveket
 */
function rotateMonthLabel() {
    let months = document.querySelectorAll('.line-scale-date-text');
    months = Array.from(months)
    if(screen.width < 991.98) {
        months.forEach(month => month.style.transform = 'rotate(30deg)')
    } else {
        months.forEach(month => month.style.transform = 'rotate(0deg)')
    }
}
