import $ from 'jquery';
window.$ = window.jQuery = $;

$(document).ready(function () {
    let sky = document.getElementById("sky");
    let march = document.getElementById("march");
    let text = document.getElementById("text");
    let landing_img = document.getElementById("landing-img");

    window.addEventListener('scroll', function (){
        var value = window.scrollY;

        //sky.style.top = -value * 0.5 + 'px';
        //march.style.top = -value * 0.01 + 'px';
        text.style.top = value * 0.5 + 'px';
    })

});
