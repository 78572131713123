import $ from "jquery";

window.$ = window.jQuery = $;

var navbar;
var lastScroll = 0;

$(document).ready(function () {
    const menuBtn = document.querySelector('.menu-btn');
    let menuOpen = false;
    menuBtn.addEventListener('click', () => {
        if(!menuOpen) {
            menuBtn.classList.add('open');
            menuOpen = true;
        } else {
            menuBtn.classList.remove('open');
            menuOpen = false;
        }
    });

    $('.navbar-collapse a').click(function(){
        $(".navbar-collapse").collapse('hide');
        menuBtn.classList.remove('open');
        menuOpen = false;
    });

    var scrollToTopBtn = document.getElementById("scrollToTopBtn");
    scrollToTopBtn.addEventListener('click',topFunction);
    window.onscroll = function() {scrollFunction()};
    navbar = document.getElementsByClassName("navbar navbar-expand-lg navbar-light shadow-sm")[0];

    if(screen.width <= 575.98){
        document.getElementById("navbar_top").style.transformOrigin = 'top';
    }
})



function scrollFunction() {
    let navbar = document.getElementById("navbar_top");

    if(screen.width >= 991.98) {
        //console.log(screen.width);
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollToTopBtn.style.display = "block";
            //navbar.classList.add("fixed-top");
            navbar.style.width = "90%";
            navbar.style.margin = "0 auto";
        } else {
            scrollToTopBtn.style.display = "none";
            navbar.style.width = "100%";
            navbar.style.margin = "0 auto";
            //navbar.classList.remove("fixed-top");
            //transitionEnd()
        }
    } else {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollToTopBtn.style.display = "block";
            //navbar.classList.add("fixed-top");
        } else {
            scrollToTopBtn.style.display = "none";
            //navbar.classList.remove("fixed-top");
        }
    }

    if(screen.width <= 575.98){
        if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20){
            //console.log(document.body.scrollTop + " " + lastScroll)
            if(document.documentElement.scrollTop > lastScroll){
                //navbar.style.transform = 'scale(1,0)';
                navbar.style.display = 'none';
                lastScroll = document.documentElement.scrollTop;
                //console.log("eltunik")
            }else {
                //navbar.style.transform = 'scale(1,1)';
                navbar.style.display = 'block';
                lastScroll = document.documentElement.scrollTop;
                //console.log("megjelenik")
            }
        }
    }
}

function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

function transitionEnd() {
    let navbar = document.getElementById("navbar_top");
    console.log("lefutott")
    navbar.addEventListener('transitionend', () => {
        navbar.classList.remove("fixed-top");
    })
    navbar.removeEventListener('transitionend', () => {
        navbar.classList.remove("fixed-top");
    });
}


