import $ from "jquery";

window.$ = window.jQuery = $;

var counterFlag = false;

function counting () {
    let counter_participant_num = 0;
    let counter_event_num = 0;
    let counter_workinghour_num = 0;
    let counter_settlement_num = 0;

    let counter_participant_end = 30000;
    let counter_event_end = 350;
    let counter_workinghour_end = 300000;
    let counter_settlement_end = 170;

    let interval = 10;

    setInterval(function(){
        if(counter_participant_num !== counter_participant_end+100) {
            document.getElementById("counter-participant").innerHTML ="+ " + counter_participant_num;
            counter_participant_num += 100;
        }
        if(counter_event_num !== counter_event_end+5) {
            document.getElementById("counter-event").innerHTML ="+ " + counter_event_num;
            counter_event_num += 5;
        }
        if(counter_workinghour_num !== counter_workinghour_end+1000) {
            document.getElementById("counter-workinghour").innerHTML ="+ " + counter_workinghour_num;
            counter_workinghour_num += 1000;
        }
        if(counter_settlement_num !== counter_settlement_end+2) {
            document.getElementById("counter-settlement").innerHTML ="+ " + counter_settlement_num;
            counter_settlement_num += 2;
        }
    },'micro', interval);
}

$(allInView);
$(window).scroll(allInView);

function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function allInView() {
    if (isScrolledIntoView(document.getElementById("counter-bar")) && !counterFlag ) {
        counting();
        counterFlag = true;
    }
}
