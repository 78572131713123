import $ from 'jquery';
window.$ = window.jQuery = $;

var landing_img_middle;
var opacity = 0.05;
var docViewTop;
var docViewBottom;
var imgBottom;
var imgTop;
var lastDocViewTop = 0;

$(document).ready(function () {
    let landing_img = $(document.getElementById("landing-img"));
    landing_img_middle = landing_img.offset().top + (landing_img.offset().top + landing_img.height() - landing_img.offset().top)/2;
    imgTop = landing_img.offset().top;
    imgBottom = imgTop + landing_img.height();
    document.addEventListener("scroll", revealText);
});

function isScrolledIntoView() {
    docViewTop = $(window).scrollTop();
    docViewBottom = docViewTop + $(window).height();
    //console.log('docViewBottom: ' + docViewBottom + ' imgBottom-100: ' + (imgBottom-100) + ' imgBottom+100: ' + (imgBottom+100))
    return (docViewBottom > imgBottom-100 && docViewBottom < imgBottom+100) /*&& (landing_img_middle > docViewTop)*/

}

function revealText() {
    let landing_img_overlay = document.getElementById("landing-img-overlay");
    let text = document.getElementById("landing-img-overlay-text");
    if(isScrolledIntoView()) {
        if(docViewTop > lastDocViewTop) {
            landing_img_overlay.style.opacity = '.5'
            text.style.opacity = '1';
        } else {
            landing_img_overlay.style.opacity = '0'
            text.style.opacity = '0';
        }
    }
    lastDocViewTop = docViewTop;
}

function debug() {
    console.log("debug");
}

/*
function allInView() {
    let landing_img = document.getElementById("landing-img-overlay");
    if (isScrolledIntoView(landing_img)) {
        if(docViewTop > lastDocViewTop) {
            landing_img.style.opacity = opacity;
                opacity += 0.01;
            console.log(opacity)
        }else if(docViewTop < lastDocViewTop) {
            landing_img.style.opacity = opacity;
            opacity -= 0.01;
            console.log(opacity)
        }else {
            landing_img.style.opacity = opacity;
        }
        lastDocViewTop = docViewTop;
    }
}

 */
