import { Loader } from "@googlemaps/js-api-loader"
import $ from "jquery";

window.$ = window.jQuery = $;

var map;
var lastInfoWindow;
var map_legend;
var map_legend_inner;
var map_legend_inner_categories;
var map_legend_inner_categories_c;
var map_legend_btns;
var markerFilter = {};
var markerArray = [];  //same as markerArray, just outside the scope
var regionalFilterBtns;
var target_group_FiltersBtns;
var projectFiltersBtns;
var programFiltersBtns;
var map_legend_close_btn;

var first_attempt = true;

var infoWindow = [];
var iconList = [];


const loader = new Loader({
    apiKey: "AIzaSyA2agxlTzr3PSt3IcNiZoSzblx_u1aFA7k",
    version: "weekly",
    mapIds: ["7bb0105e38aa392f"]
});

function debug(msg) {
    console.log(msg);
}

$( document ).ready(function() {
    map_legend = document.getElementById('map-category');
    map_legend_inner = document.getElementById('map-category-inner');
    map_legend_inner_categories = document.getElementById('map-category-inner-cs');
    map_legend_inner_categories_c = Array.from(document.getElementsByClassName('map-category-inner-c'));
    map_legend_btns = Array.from(document.getElementsByClassName('btn map'));
    map_legend_close_btn = document.getElementById('map-category-inner-cs-close-btn')

    regionalFilterBtns = Array.from(document.querySelectorAll('.map-territorial-btns li'));
    target_group_FiltersBtns = Array.from(document.querySelectorAll('#target-group-filters div'));
    projectFiltersBtns = Array.from(document.querySelectorAll('#project-filters div'));
    programFiltersBtns = Array.from(document.querySelectorAll('#program-filters div'));

    //console.log(String(regionalFilterBtns[0]))

    mapLegendBtnsInit();

    map_legend.addEventListener('mouseenter', increaseLegendSize)

    mapInit();
});

function increaseLegendSize () {
    //debug(Date.now()+"increaseLegendSize")
    map_legend_inner_categories.removeEventListener('transitionend', decr )
    map_legend.style.transform = 'translateX(45%) translateY(-82.5%) scale(2,2.8)';
    document.querySelector('#map-category-inner-cs').style.transform = 'scale(1.2,0.8571)';

    map_legend_inner.style.opacity = '0';
    if( first_attempt )
        map_legend_inner.addEventListener('transitionend', incr())
    else
        map_legend_inner.addEventListener('transitionend', incr)
    map_legend_inner_categories.style.opacity = '1';
    map_legend.removeEventListener('mouseenter', increaseLegendSize);
    map_legend.addEventListener('mouseleave', decreaseLegendSize)
    //debug(Date.now()+"ENDincreaseLegendSize")
}

function decreaseLegendSize () {
    //debug(Date.now()+"decreaseLegendSize")
    map_legend_inner.removeEventListener('transitionend', incr)
    map_legend.style.transform = 'scale(1,1)';
    map_legend_inner_categories.style.opacity = '0';
    map_legend_inner_categories.addEventListener('transitionend', decr)
    map_legend_inner.style.opacity = '1';
    map_legend.removeEventListener('mouseleave', decreaseLegendSize);
    map_legend.addEventListener('mouseenter', increaseLegendSize)
    //debug(Date.now()+"ENDdecreaseLegendSize")
}

function mapLegendBtnsInit() {
    //map_legend_btns[0].classList.add('active');
    //markerFilter = map_legend_btns[0].getAttribute('data-meaning');
    map_legend_btns.forEach(btn => btn.addEventListener('click', selectBtn));
    map_legend_close_btn.addEventListener('click',decreaseLegendSize);
    regionalFilterBtns.forEach(btn => btn.addEventListener('click', selectBtn));
}

function selectBtn() {
    /*
    map_legend_btns.forEach(btn => btn.classList.remove('active'));
    this.classList.add('active');
    markerFilter = this.getAttribute('data-meaning');
    mapMarkerInit();
     */
    if(this.parentElement.id === 'map_territorial_btns_above' || this.parentElement.id === 'map_territorial_btns_below')
        checkSelectedBtns(regionalFilterBtns,this)
    if(this.parentElement.id === 'target-group-filters')
        checkSelectedBtns(target_group_FiltersBtns,this);
    if(this.parentElement.id === 'project-filters')
        checkSelectedBtns(projectFiltersBtns,this);
    if(this.parentElement.id === 'program-filters')
        checkSelectedBtns(programFiltersBtns,this);

    mapMarkerInit();
}

function mapMarkerInit() {
    markerArray.forEach(marker => {
        marker.setMap(null);
    });
    markerArray = [];
    infoWindow = [];
    $.ajax({
        url: '/map-data.js',
        type: 'GET',
        dataType: 'json',
        data: markerFilter,
    })
    .done(function(data) {
        data.forEach(item => {
            if (
              ( 
                markerFilter['map_territorial_btns_above'] == undefined ||
                markerFilter['map_territorial_btns_above'] == '' ||
                markerFilter['map_territorial_btns_above'] == item.alliance
              ) && (
                markerFilter['map_territorial_btns_below'] == undefined ||
                markerFilter['map_territorial_btns_below'] == '' ||
                markerFilter['map_territorial_btns_below'] == item.district
              ) && (
                markerFilter['target-group-filters'] == undefined ||
                markerFilter['target-group-filters'] == '' ||
                markerFilter['target-group-filters'] == item.target
              ) && (
                markerFilter['project-filters'] == undefined ||
                markerFilter['project-filters'] == '' ||
                markerFilter['project-filters'] == item.project
              ) && (
                markerFilter['program-filters'] == undefined ||
                markerFilter['program-filters'] == '' ||
                markerFilter['program-filters'] == item.event_type
              )
            ) {
                markerArray.push(
                    new google.maps.Marker({
                        position: { lat: parseFloat(item.gps_lat), lng: parseFloat(item.gps_lon) },
                        icon: getIcon(iconList,item.icon),
                        map: map,
                        animation: google.maps.Animation.DROP,
                        id: item.id,
                        optimized: false
                    })
                );
                var contentString = generateContentString(item.image,item.event_code,item.project,item.name,item.headcount,item.date,item.description,item.location,item.gps_lat,item.gps_lon, item.tags, item.link)
                infoWindow.push(
                    new google.maps.InfoWindow({
                        id:item.id,
                        content: contentString,
                    }),
                );
            }
        });

        markerArray.forEach(marker => marker.addListener("click", () => {
            if( lastInfoWindow !== undefined )
                lastInfoWindow.close();
            let iw_temp = infoWindow[markerArray.indexOf(marker)];
            iw_temp.open(map, marker);
            lastInfoWindow = iw_temp;
        }));

    })
    .fail(function() {
        console.log("error");
    })
    .always(function() {
        console.log("complete");
    });
}

function checkSelectedBtns(array, this_btn) {
    markerFilter['map_territorial_btns_above'] = '';
    markerFilter['map_territorial_btns_below'] = '';

    array.forEach ( btn => {
        if( btn === this_btn ) {
            if(btn.classList.contains('active')) {
                this_btn.classList.remove('active');
                markerFilter[this_btn.parentElement.id] = '';
            }else {
                btn.classList.add('active')
                markerFilter[this_btn.parentElement.id] = btn.getAttribute('data-meaning');
                debug(markerFilter);
            }
        } else {
            btn.classList.remove('active');
        }
    })
}


function incr() {
    //debug(Date.now()+"incr")
    map_legend_inner.style.display = 'none';
    map_legend_inner_categories.style.display = 'block';
}

function decr() {
    //debug(Date.now()+"decr")
    map_legend_inner_categories.style.display = 'none';
    map_legend_inner.style.display = 'block';
}

function generateContentString(image,event_code,project_code,title,people_num,date,text,location,coord_lat, coord_lng, tags,link) {
    return  '<div class="iw-content">' +
            (image.length > 0 ? '<img class="iw-image" src="https://efop.s3.eu-central-1.amazonaws.com/public/photos/shares/map/event_images/' + image + '">' : '') +
            '<div class="iw-content-body">' +
            '<p class="iw-content-date">Dátum: <strong>' + String(date) + '</strong></p>' +
            '<div class="iw-content-location">' +
            '<p class="iw-content-coord">Helyszín: ' + '<strong>' + location + '</strong></p>' +
            '<p class="iw-content-coord">Hosszúság: <strong>' + String(coord_lat) + '</strong></p>' +
            '<p class="iw-content-coord">Szélesség: <strong>' + String(coord_lng) + '</strong></p>' +
            '</div>' +
            '<div class="iw-content-datas">' +
            '<p class="iw-content-data">Projektkód: <strong>' + project_code + '</strong></p>' +
            '<p class="iw-content-data">Rendezvénykód: <strong>' + event_code + '</strong></p>' +
            '<p class="iw-content-data">Létszám: <strong>' + String(people_num) + '</strong> fő</p>' +
            '<div class="iw-content-tags">' +
            generateTags(tags) +
            '</div>' +
            '</div>' +
            '<h4 class="iw-title">'+ title +'</h4>' +
            '<p class="iw-content-text">' + text + '</p>' +
            '<a class="iw-content-link" href="/' + String(link) +'">Itt tudhatsz meg róla többet</a>' +
            '<div class="iw-spacer"></div>' +
            "</div>" +
            "</div>"
}

function generateTags(tags) {
    var string = '';
    tags.forEach(tag => {
        string += '<div class="iw-content-tag">' + tag + '</div>';
    })
    return string;
}

window.openSelectMarkerInfoWindow = function(id) {
    if(lastInfoWindow !== undefined) {
        lastInfoWindow.close();
    }
    lastInfoWindow = null;
    markerArray.forEach(marker => {
        if(marker['id'] == id) {
            infoWindow.forEach(iw => {
                if(iw.id == id) {
                    lastInfoWindow = iw;
                    iw.open(map,marker);
                }
            })
        }
    });
    if ( ! lastInfoWindow) {
        $.ajax({
            url: '/map-data.js?id='+id,
            type: 'GET',
            dataType: 'json',
            data: markerFilter,
        })
        .done(function(data) {
            data.forEach(item => {
                var marker = new google.maps.Marker({
                    position: { lat: parseFloat(item.gps_lat), lng: parseFloat(item.gps_lon) },
                    icon: getIcon(iconList,item.icon),
                    map: map,
                    animation: google.maps.Animation.DROP,
                    id: item.id,
                    optimized: false
                });
                markerArray.push(marker);

                var contentString = generateContentString(item.image,item.event_code,item.project,item.name,item.headcount,item.date,item.description,item.location,item.gps_lat,item.gps_lon, item.tags, item.link)
                lastInfoWindow = new google.maps.InfoWindow({
                    id: item.id,
                    content: contentString,
                });
                lastInfoWindow.open(map,marker);
                infoWindow.push(lastInfoWindow);
            });
        });
    }

}


function getIcon(iconList,icon) {
    return iconList.find(elem => elem.name === icon)
}

function mapInit() {

    loader.load().then(() => {

        map = new google.maps.Map(document.getElementById("map"), {
            mapId: '7bb0105e38aa392f',
            center: { lat: 47.165869, lng: 20.036858 },
            zoom: 7,
            options: {
                gestureHandling: 'cooperative',
                streetViewControl: false
            },
            //scrollwheel: false,
        });

        const ncser_efop_136_felk_icon = {
            url:
                window.asset_url + "images/map/marker/nonscout-136-preparation-icon.svg",
            // This marker is 20 pixels wide by 32 pixels high.
            scaledSize: new google.maps.Size(48, 68),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(24, 68),
            name: 'ncser_efop_136_felk'
        };

        const ncser_efop_136_pr_icon = {
            url: window.asset_url + "images/map/marker/nonscout-136-program-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'ncser_efop_136_pr'
        };

        const ncser_efop_1121_felk_icon = {
            url: window.asset_url + "images/map/marker/nonscout-1121-preparation-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'ncser_efop_1121_felk'
        };

        const ncser_efop_1121_pr_icon = {
            url: window.asset_url + "images/map/marker/nonscout-1121-program-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'ncser_efop_1121_pr'
        };

        const is_efop_136_felk_icon = {
            url: window.asset_url + "images/map/marker/opened-136-preparation-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'is_efop_136_felk'
        };

        const is_efop_136_pr_icon = {
            url: window.asset_url + "images/map/marker/opened-136-program-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'is_efop_136_pr'
        };

        const is_efop_1121_pr_icon = {
            url: window.asset_url + "images/map/marker/opened-1121-program-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'is_efop_1121_pr'
        };

        const is_efop_1121_felk_icon = {
            url: window.asset_url + "images/map/marker/opened-1121-preparation-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'is_efop_1121_felk'
        };

        const cser_efop_1121_felk_icon = {
            url: window.asset_url + "images/map/marker/scout-1121-preparation-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'cser_efop_1121_felk'
        };

        const cser_efop_1121_pr_icon = {
            url: window.asset_url + "images/map/marker/scout-1121-program-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'cser_efop_1121_pr'
        };

        const cser_efop_136_pr_icon = {
            url: window.asset_url + "images/map/marker/scout-136-program-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'cser_efop_136_pr'
        };

        const cser_efop_136_felk_icon = {
            url: window.asset_url + "images/map/marker/scout-136-preparation-icon.svg",
            scaledSize: new google.maps.Size(48, 68),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(24, 68),
            name: 'cser_efop_136_felk'
        };

        iconList = [ncser_efop_136_felk_icon,ncser_efop_136_pr_icon,ncser_efop_1121_felk_icon,ncser_efop_1121_pr_icon,
            is_efop_136_felk_icon,is_efop_136_pr_icon,is_efop_1121_pr_icon,is_efop_1121_felk_icon,cser_efop_1121_felk_icon,
            cser_efop_1121_pr_icon,cser_efop_136_pr_icon,cser_efop_136_felk_icon]

        const legendIcon = {
            path:
                "M60,30A30,30,0,1,1,30,0,30,30,0,0,1,60,30Z",
            fillColor: "#f39200",
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            anchor: new google.maps.Point(30, 30),
            size: new google.maps.Size(60, 60),
            origin: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(30, 30),
        };

        /*
            InfoWindow bezárása, ha mellé kattintunk
        */
        google.maps.event.addListener(map, "click", function(event) {
            if(lastInfoWindow !== undefined) {
                lastInfoWindow.close();
            }
        });

        const legend = document.getElementById("map-category");
        map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);

        mapMarkerInit();

    })
}
